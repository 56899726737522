import dolgu from "./dolgu.jpg";
import implant from "./implant.jpg";
import kanal from "./kanal.jpg";
import ortodontik_tedavi from "./ortodontik_tedavi.jpg";
import cocuk_dis from "./cocuk_dis.jpg";
import dis_beyazlatma from "./dis_beyazlatma.jpg";
import dis_eti_tedavisi from "./dis_eti_tedavisi.jpg";
import konservatif from "./konservatif.jpg";
import seffaf_plak from "./seffaf_plak.jpg";
import lazer_dis from "./lazer_dis.jpg";
import estetik_dis from "./estetik_dis.jpg";

export const treatmentImages = [
    {
        image: dolgu,
        alt: "Dolgu Tedavisi",
        title: "Dolgu Tedavisi"
    },
    {
        image: implant,
        alt: "İmplant",
        title: "İmplant Tedavisi"
    },
    {
        image: kanal,
        alt: "Kanal Tedavisi",
        title: "Kanal Tedavisi"
    },
    {
        image: ortodontik_tedavi,
        alt: "Ortodontik Diş Tedavisi",
        title: "Ortodontik Diş Tedavisi"
    },
    {
        image: cocuk_dis,
        alt: "Çocuk Diş Hekimliği",
        title: "Çocuk Diş Hekimliği"
    },
    {
        image: dis_beyazlatma,
        alt: "Diş Beyazlatma",
        title: "Diş Beyazlatma"
    },
    {
        image: dis_eti_tedavisi,
        alt: "Diş Eti Tedavileri",
        title: "Diş Eti Tedavileri"
    },
    {
        image: estetik_dis,
        alt: "Estetik Diş Hekimliği",
        title: "Estetik Diş Hekimliği"
    },
    {
        image: lazer_dis,
        alt: "Lazer Destekli Diş Hekimliği",
        title: "Lazer Destekli Diş Hekimliği"
    },
    {
        image: seffaf_plak,
        alt: "Şeffaf Plak Tedavisi",
        title: "Şeffaf Plak Tedavisi"
    },
    {
        image: konservatif,
        alt: "Konservatif Diş Tedavisi",
        title: "Konservatif Diş Tedavisi"
    }
];