import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./Social.css";

const Social = ({ url, title, icon}) => {
    return (
        url !== "" ?
        <a href={url} title={title} target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={icon} className={title} />
        </a> : 
        <a href="#footer" title={title}>
            <FontAwesomeIcon icon={icon} className={title} />
        </a>
    )
}

export default Social;