import darkBg from "./darkBg.jpg";
import logo_with_text from "./logo_with_text.png";
import logo_without_text from "./logo_without_text.png";
import logo_without_text_2 from "./logo_without_text_2.png";
import logo_without_text_2_50x50 from "./logo_without_text_2_50x50.png";
import about from "./about.jpg";

export const images = {
    darkBg,
    logo_with_text,
    logo_without_text,
    logo_without_text_2,
    logo_without_text_2_50x50,
    about
};