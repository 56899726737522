import Offcanvas from 'react-bootstrap/Offcanvas';
import "./SearchCanvas.css";

const SearchCanvas = ({ showCanvas, handleClose }) => {
    
    return (
        <Offcanvas show={showCanvas} onHide={handleClose} scroll={true} backdrop={false}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <input type="text" name="search" id="searchInput" autoFocus placeholder='Ara...'/>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                Diş hekimlerimizi, Ağız ve Diş Sağlığı Hizmetlerimizi Arayın...
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SearchCanvas