import "./TreatmentCard.css";

const TreatmentCard = ({image, alt, title}) => {
    return (
        <div className="treatment">
            <div className="imageContainer">
                <img src={image} alt={alt} />
            </div>
            <h2>{title}</h2>
        </div>
    )
}

export default TreatmentCard