import React from 'react'
import "./Footer.css";
import { images } from "../../assets/images";
import { faEnvelopeOpen, faPhoneAlt, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Social from 'components/Social/Social';
import { companyInfo } from 'data/companyInfo';
import Navbar from 'react-bootstrap/Navbar';
import BottomBar from 'components/BottomBar/BottomBar';

const Footer = () => {
    return (
        <footer id = "footer">
            <div className="footerContainer">
                <div className="footerLeft">
                    <Navbar.Brand href="#home">
                        <img className='logo' src={images.logo_with_text} alt="" />
                    </Navbar.Brand>
                </div>
                <div className="footerCenter">
                    <h5>Hızlı Linkler</h5>
                    <ul>
                        <li><a href="#topbar"><FontAwesomeIcon icon={faAnglesRight}/> Anasayfa</a></li>
                        <li><a href="#aboutContainer"><FontAwesomeIcon icon={faAnglesRight}/> Hakkımızda</a></li>
                        <li><a href="#servicesContainer"><FontAwesomeIcon icon={faAnglesRight}/> Servislerimiz</a></li>
                        <li><a href="#dentistsContainer"><FontAwesomeIcon icon={faAnglesRight}/> Diş Hekimlerimiz</a></li>
                        {/* <li><a href="#appointment"><FontAwesomeIcon icon={faAnglesRight}/> Randevu Al</a></li> */}
                        <li><a href="#contact"><FontAwesomeIcon icon={faAnglesRight}/> İletişim</a></li>
                    </ul>
                </div>
                <div className="footerRight">
                    <h5>Bize Ulaşın</h5>
                    <ul className='contactUs'>
                        <li><FontAwesomeIcon icon={faEnvelopeOpen} /> <span>E-Posta:</span> destek@vadidiserzurum.com</li>
                        <li><FontAwesomeIcon icon={faPhoneAlt} /> <span>Telefon:</span> 0 (544) 134 08 36</li>
                        <li><span>Adres: </span> Adnan Menderes Mah. Rabia Sokak Yazıcılar İnşaat A / Blok No: 8-A <br /> Palandöken / <h1>Erzurum</h1></li>
                    </ul>
                    <br />
                    <h5>Sosyal Medya</h5>
                    <ul className='footerSocial'>
                        {companyInfo.social.map((sc, index) => (
                            <li key={`company_social_${index}`}>
                                <Social
                                    url={sc.url}
                                    title={sc.title}
                                    icon={sc.icon}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <hr />
            <BottomBar />
        </footer>
    )
}

export default Footer