import "./GoogleMap.css";

const GoogleMap = () => {
    return (
        <section id='googleMap'>
            {/* <h1>Vadi Erzurum Ağız ve Diş Sağlığı Polikliniği</h1> */}
            <iframe title='googleMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2574.2831532414643!2d41.242811687536545!3d39.888664628744536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406e5f8534c9627f%3A0x42e024b6e766496b!2zVmFkaSBFcnp1cnVtIEHEn8SxeiB2ZSBEacWfIFNhxJ9sxLHEn8SxIFBvbC4!5e0!3m2!1str!2str!4v1709584355698!5m2!1str!2str" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </section>

    )
}

export default GoogleMap