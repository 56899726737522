import carousel1 from "./carousel_1.jpg";
import carousel2 from "./carousel_2.jpg";
import carousel3 from "./carousel_3.jpg";
import carousel4 from "./carousel_4.jpg";

export const carousels = {
    carousel1,
    carousel2,
    carousel3,
    carousel4
};