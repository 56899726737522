import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./NNavbar.css";
import { images } from "../../assets/images";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMagnifyingGlass,
    faMoon,
    faSun
} from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';
import SearchCanvas from 'components/SearchCanvas/SearchCanvas';

const NNavbar = () => {

    const [themeIcon, setThemeIcon] = useState(faMoon);

    const changeTheme = () => {
        document.body.classList.toggle("dark");
        if (document.body.classList.contains("dark")) {
            setThemeIcon(faSun);
        }
        else {
            setThemeIcon(faMoon);
        }
    }


    const [showCanvas, setShowCanvas] = useState(false);

    const toggleCanvas = () => setShowCanvas((s) => !s);

    const handleClose = () => setShowCanvas(false);

    return (
        <>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <img className='logo' src={images.logo_without_text_2_50x50} alt="" />
                        <h1>Vadi Erzurum <br /> Ağız ve Diş Sağlığı Polikliniği</h1>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto"></Nav>
                        <Nav>
                            <Nav.Link href="#topbar">Anasayfa</Nav.Link>
                            <Nav.Link href="#aboutContainer">Hakkımızda</Nav.Link>
                            <Nav.Link href="#treatmentsContainer">Tedavilerimiz</Nav.Link>
                            <Nav.Link href="#footer">İletişim</Nav.Link>
                            {/* TODO Search */}
                            <Nav.Link onClick={() => toggleCanvas()}>
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </Nav.Link>
                            <Nav.Link onClick={() => changeTheme()}>
                                <FontAwesomeIcon icon={themeIcon} />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <SearchCanvas showCanvas = {showCanvas} handleClose = {handleClose}/>
        </>

    )
}

export default NNavbar;