import React from 'react';
import "./DentistCard.css";
import Social from 'components/Social/Social';

const DentistCard = ({ image, alt, fullName, social, title}) => {
    return (
        <div className="dentistCard">
            <div className="box">
                <div className="imgBx">
                    <img src={image} alt={alt}/>
                    <h3>{fullName}</h3>
                    <hr />
                    <h2>{title}</h2>
                </div>
                <div className="contentBx">
                    <div>
                        <h3>{title}</h3>
                        <p>
                            {social.map((sc, index) => (
                                <Social
                                    key={`dt_social_${index}`}
                                    url={sc.url}
                                    title={sc.title}
                                    icon={sc.icon}
                                />
                            ))}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DentistCard;