import {
    faFacebookSquare,
    faLinkedin,
    faInstagram,
    faTwitter
} from "@fortawesome/free-brands-svg-icons";

export const companyInfo = {
    social: [
        {
            title: "linkedIn",
            icon: faLinkedin,
            url: ""
        },
        {
            title: "facebook",
            icon: faFacebookSquare,
            url: ""
        },
        {
            title: "instagram",
            icon: faInstagram,
            url: "https://www.instagram.com/vadierzurum"
        },
        {
            title: "twitter",
            icon: faTwitter,
            url: ""
        }
    ],
};