import React from 'react'
import "./Dentists.css";
import { dentistImages } from 'assets/images/dentists';
import DentistCard from './DentistCard/DentistCard';

const Dentists = () => {
    return (
        <section id="dentistsContainer">
            <div id='dentists'>
                <h1>Diş Hekimlerimiz</h1>
                <section id='dentistCardsContainer'>
                    { dentistImages.map((dentistImage, index) => (
                        <DentistCard
                            key={`dentist_${index}`}
                            image={dentistImage.image}
                            alt={dentistImage.alt}
                            fullName={dentistImage.fullName}
                            social={dentistImage.social}
                            title={dentistImage.title}
                        />
                    ))}
                </section>
            </div>
        </section>
    )
}

export default Dentists